import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { post, get, del } from '.'

export interface PaymentRequest {
  action: string;
  params: Record<string, string | number>;
}

export interface OrderProduct {
  exposition_id?: string
  description?: string
  type: string
  id: string
  name: string
  period_id?: string
  period_from?: string
  period_until?: string
  price: number
  quantity: number
  voucher?: string
  totalPrice?: number
  activity_group?: string
  main_period_id?: string
}

export interface CombinedVoucherOrderProduct extends OrderProduct {
  vouchers: string[]
}

export interface Order {
  id?: string
  account_id?: string
  customer: {
    email: string
    first_name: string
    last_name: string
    newsletter: boolean
    phone?: string
    terms: boolean
  }
  customer_id: string
  locale?: string
  payment: {
    amount: number
    method: string
    issuer?: string
  }
  products: OrderProduct[]
}

export interface PostOrderResponse {
  id: string
  paymentRequest?: PaymentRequest
  redirect_url?: string
}

export const createOrder = (accountId: string, data: Order): AxiosPromise<PostOrderResponse> =>
  post({
    url: '/api/orders',
    params: { account_id: accountId },
    data,
  })

export const clearSession = (orderId: string): AxiosPromise<void> =>
  del({
    url: `/api/sessions/${orderId}`,
  })

export const postOrder = (accountId: string, data: Order, config: AxiosRequestConfig): AxiosPromise<PostOrderResponse> =>
  post({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/orders`,
    data,
    ...config,
  })

export interface GetOrderStatusResponse {
  order_number?: string
  status: 'pending' | 'paid'
  tickets?: string[]
  total: number
  isSomerdrome: boolean
  isBrewAtTheZoo: boolean
  expositionIds?: string[]
}

export const getOrderStatus = (accountId: string, orderId: string): AxiosPromise<GetOrderStatusResponse> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/orders/${orderId}/status`,
  })
