import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Formik, FormikHelpers, Field, FormikErrors, FormikTouched } from 'formik'
import { AxiosResponse, AxiosPromise } from 'axios'
import styled from 'styled-components'
import { getPaymentMethods, PaymentMethod as PaymentMethodType } from '../../api/cartApi'
import { Order, OrderProduct, PostOrderResponse, PaymentRequest } from '../../api/orderApi'
import { confirmOrder, setId, SetIdAction, setCustomer } from '../../actions/orderActions'
import { AppState } from '../../redux/reducers'
import { getCartPrice, getCustomerId, getCustomer, getOrderProducts } from '../../selectors/orderSelectors'
import { CustomerState } from '../../redux/orderReducer'
import { Account } from '../../redux/envReducer'
import Loader from '../loaders/Loader'
import { ThunkDispatch } from '../../redux/types'
import { getLocale, getAccount } from '../../selectors/envSelectors'
import {
  H2,
  Col,
  Hr,
  SubTitle,
  ErrorMessage,
  UnorderedList,
  CheckboxField,
  PaymentMethodsField,
  Label,
  Row,
  Button,
  Alert,
  AlertType,
  HelpLabel,
  Form,
  Center,
  FormGroup,
  TextField,
  Translate,
  RTEContent,
} from '@jstack/libema-design-system'
import analyticsUtils from '../../utils/analyticsUtils'
import { ACCOUNT_NAMES } from '../../data/accounts'
import AutoSubmitForm from '../common/AutoSubmitForm'
import { capitalize } from '../../utils/stringUtils'
interface CheckoutFormState {
  error: boolean
  fetchError: boolean
  isFetchingPaymentMethods: boolean
  paymentMethods: PaymentMethodType[]
  paymentRequest: PaymentRequest
}

export interface CheckoutFormValues {
  email: string
  email_verify: string
  first_name: string
  issuer: string
  last_name: string
  method: string
  newsletter: boolean
  phone: string // only required for klimrijk (quick fix)
  terms: boolean
}

interface CheckoutFormErrors {
  issuer?: string | React.ReactElement
  method?: string | React.ReactElement
  email?: string | React.ReactElement
  email_verify?: string | React.ReactElement
  first_name?: string | React.ReactElement
  last_name?: string | React.ReactElement
  phone?: string | React.ReactElement
  terms?: string | React.ReactElement
}

interface CheckoutFormProps extends RouteComponentProps {
  account: Account | null
  confirmOrder: (data: Order) => AxiosPromise<PostOrderResponse>
  customer: CustomerState | undefined
  customerId: string
  locale: string
  setOrderId: (id: string) => SetIdAction
  setCustomer: (customer: CustomerState) => void
  totalPrice: number
  orderProducts: OrderProduct[]
  checkoutWarningMessage: string | null
}

const ErrorSummary = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const WarningCheckoutMessage = styled.div`
  background-color: ${({ theme }) => theme.color.neutralDark};
  color: #fff;
  padding: ${({ theme }) => theme.padding.md};
  margin-bottom: ${({ theme }) => theme.margin.md};
`

class CheckoutForm extends React.Component<CheckoutFormProps, CheckoutFormState> {
  state = {
    error: false,
    fetchError: false,
    isFetchingPaymentMethods: false,
    paymentMethods: [],
    paymentMethodsError: false,
    paymentRequest: {
      action: '',
      params: {},
    },
  }

  componentDidMount = () => {
    const { totalPrice, account } = this.props
    if (totalPrice > 0) {
      this.setState({ isFetchingPaymentMethods: true })
      getPaymentMethods(account?.id)
        .then((res: AxiosResponse) => {
          const paymentMethods = res.data.map((method: any) => {
            return {
              ...method,
              issuers:
                method.options &&
                method.options.map((option: any) => ({
                  name: option.issuerid,
                  label: option.label,
                })),
            }
          })
          this.setState({
            isFetchingPaymentMethods: false,
            paymentMethods,
          })
        })
        .catch(() =>
          this.setState({
            fetchError: true,
            isFetchingPaymentMethods: false,
          })
        )
    }
  }

  getTouchedErrors = (errors: FormikErrors<CheckoutFormValues>, touched: FormikTouched<CheckoutFormValues>): string[] => {
    return Object.keys(errors).filter((key: string) => typeof touched[key] !== 'undefined')
  }

  handleSubmit = (values: CheckoutFormValues, { setSubmitting }: FormikHelpers<CheckoutFormValues>) => {
    // if (!this.props.customer) {
    //   const err = new Error('Customer cannot be empty during payment form submit')
    //   return Promise.reject(err)
    // }

    const { account } = this.props

    const order: Order = {
      customer: {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        newsletter: values.newsletter,
        terms: values.terms,
      },
      customer_id: this.props.customerId,
      payment: {
        amount: Math.round(this.props.totalPrice * 100),
        method: values.method,
      },
      products: [],
    }

    if (account?.name === ACCOUNT_NAMES.KLIMRIJK) {
      order.customer.phone = values.phone
    }

    this.props.setCustomer(order.customer)

    if (this.props.totalPrice === 0) {
      order.payment.method = 'none'
    }

    if (values.method === 'ideal') {
      order.payment.issuer = values.issuer
    }

    return this.props
      .confirmOrder(order)
      .then((res) => {
        this.props.setOrderId(res.data.id)
        if (res.data.redirect_url) {
          window.location.href = res.data.redirect_url
        } else if (res.data.paymentRequest) {
          this.setState({ paymentRequest: res.data.paymentRequest })
        } else {
          throw new Error('No redirect url found')
        }
      })
      .catch((err) => {
        this.setState({ error: true })
        setSubmitting(false)
      })
  }

  render = () => {
    const { account, customer, locale, orderProducts } = this.props
    const { paymentMethods, isFetchingPaymentMethods, fetchError, paymentRequest } = this.state

    return (
      <>
        {paymentRequest?.action !== '' && <AutoSubmitForm {...paymentRequest} />}
        <Formik
          initialValues={{
            email: customer ? customer.email : '',
            email_verify: '',
            first_name: customer ? customer.first_name : '',
            issuer: 'ABNANL2A',
            last_name: customer ? customer.last_name : '',
            method: '',
            phone: '',
            newsletter: customer ? customer.newsletter : true,
            terms: customer ? customer.terms : false,
          }}
          validate={(values: CheckoutFormValues) => {
            const errors: CheckoutFormErrors = {}
            if (this.props.totalPrice > 0) {
              if (!values.method) {
                errors.method = <Translate id="validator.payment_method_required" />
              }

              if (values.method === 'ideal' && !values.issuer) {
                errors.issuer = <Translate id="validator.ideal_issuer_required" />
              }
            }

            if (!values.email) {
              errors.email = <Translate id="validator.email_required" />
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = <Translate id="validator.email_invalid" />
            }

            if (values.email_verify !== values.email) {
              errors.email_verify = <Translate id="validator.email_mismatch" />
            }

            if (!values.first_name) {
              errors.first_name = <Translate id="validator.first_name_required" />
            }

            if (!values.last_name) {
              errors.last_name = <Translate id="validator.last_name_required" />
            }

            if (!values.terms) {
              errors.terms = <Translate id="validator.terms_required" />
            }

            if (account?.name === ACCOUNT_NAMES.KLIMRIJK && !values.phone) {
              errors.phone = <Translate id="validator.phone_required" />
            }

            return errors
          }}
          onSubmit={this.handleSubmit}
        >
          {({ errors, isSubmitting, touched }) => (
            <Form className="checkout">
              <Row>
                <Col col={12} md={10} mdOffset={1}>
                  <H2>
                    <Translate id="form.customer.title" />
                  </H2>
                  <SubTitle>
                    <Translate id="form.customer.subtitle" />
                  </SubTitle>
                  <Row>
                    <Col col={12} md={6}>
                      <FormGroup>
                        <Label required={true}>
                          <Translate id="label.first_name" />
                        </Label>
                        <Field name="first_name" type="text" component={TextField} />
                        <ErrorMessage name="first_name" />
                      </FormGroup>
                    </Col>
                    <Col col={12} md={6}>
                      <FormGroup>
                        <Label required={true}>
                          <Translate id="label.last_name" />
                        </Label>
                        <Field name="last_name" type="text" component={TextField} />
                        <ErrorMessage name="last_name" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col col={12}>
                      <FormGroup>
                        <Label required={true}>
                          <Translate id="label.email" />
                        </Label>
                        <Field name="email" type="email" component={TextField} />
                        <HelpLabel>
                          <Translate id="help.email" />
                        </HelpLabel>
                        <ErrorMessage name="email" />
                      </FormGroup>
                    </Col>
                    <Col col={12}>
                      <FormGroup>
                        <Label required={true}>
                          <Translate id="label.email_verify" />
                        </Label>
                        <Field name="email_verify" type="email" component={TextField} />
                        <ErrorMessage name="email_verify" />
                      </FormGroup>
                    </Col>
                  </Row>
                  {account?.name === ACCOUNT_NAMES.KLIMRIJK && (
                    <Row>
                      <Col col={12}>
                        <FormGroup>
                          <Label required={true}>
                            <Translate id="label.phone" />
                          </Label>
                          <Field name="phone" type="text" component={TextField} />
                          <ErrorMessage name="phone" />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col col={12}>
                      <FormGroup inline={true}>
                        <Field name="newsletter" id="newsletter" component={CheckboxField} />
                        <Label htmlFor="newsletter">
                          <Translate id="label.newsletter" values={{ name: capitalize(account?.name || '') }} />
                        </Label>
                      </FormGroup>
                      <FormGroup inline={true}>
                        <Field
                          name="terms"
                          id="terms"
                          component={CheckboxField}
                          onChange={(checked: boolean | any[]) => checked && analyticsUtils.checkoutAcceptTerms(orderProducts)}
                        />
                        <Label htmlFor="terms" required={true} noMargin={true}>
                          <Translate id="label.agree_with" />
                          &nbsp;
                          <a href={account?.terms[locale]} target="_blank">
                            <Translate id="label.terms" />
                          </a>
                          ,&nbsp;
                          <a href={account?.privacystatement[locale]} target="_blank">
                            <Translate id="label.privacy_policy" />
                          </a>
                          &nbsp;
                          <Translate id="label.and" />
                          &nbsp;
                          <a href={account?.parkregulations[locale]} target="_blank">
                            <Translate id="label.park_regulations" />
                          </a>
                        </Label>
                        <ErrorMessage name="terms" />
                      </FormGroup>
                      <FormGroup>
                        <Translate id="label.fields_required" />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.props.totalPrice > 0 && (
                <Row>
                  <Col col={12} md={10} mdOffset={1}>
                    <Hr />
                    <H2 centered={true}>
                      <Translate id="title.payment_method" />
                    </H2>
                    <SubTitle>
                      <Translate id="title.payment_method_subtitle" />
                    </SubTitle>
                    {isFetchingPaymentMethods && (
                      <Center>
                        <Loader />
                      </Center>
                    )}
                    {fetchError && (
                      <Alert type={AlertType.ERROR}>
                        <Translate id="error.paymentmethods_fetch_failed" />
                      </Alert>
                    )}
                    {isFetchingPaymentMethods === false && (
                      <Field
                        name="method"
                        nameIssuer="issuer"
                        component={PaymentMethodsField}
                        methods={paymentMethods}
                        onChange={(paymentMethod: string) => analyticsUtils.checkoutPaymentMethod(paymentMethod, orderProducts)}
                      />
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col col={12} md={10} mdOffset={1}>
                  {this.getTouchedErrors(errors, touched).length > 0 && (
                    <ErrorSummary type={AlertType.ERROR}>
                      <UnorderedList>
                        {this.getTouchedErrors(errors, touched).map((key) => (
                          <li key={key}>{errors[key]}</li>
                        ))}
                      </UnorderedList>
                    </ErrorSummary>
                  )}
                  {this.state.error && (
                    <ErrorSummary type={AlertType.ERROR}>
                      <Translate id="error.order_confirmation_failed" />
                    </ErrorSummary>
                  )}

                  {this.props.checkoutWarningMessage && (
                    <WarningCheckoutMessage>
                      <RTEContent dangerouslySetInnerHTML={{ __html: this.props.checkoutWarningMessage }} />
                    </WarningCheckoutMessage>
                  )}

                  <Center>
                    <Button disabled={isSubmitting} type="submit">
                      {isSubmitting && <Loader />}
                      &nbsp;
                      <Translate id="button.payment_submit" />
                    </Button>
                  </Center>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    )
  }
}

const mapState = (state: AppState) => ({
  account: getAccount(state),
  customer: getCustomer(state),
  customerId: getCustomerId(state),
  locale: getLocale(state),
  totalPrice: getCartPrice(state),
  orderProducts: getOrderProducts(state),
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  confirmOrder: (data: Order) => dispatch(confirmOrder(data)),
  setCustomer: (customer: CustomerState) => dispatch(setCustomer(customer)),
  setOrderId: (id: string) => dispatch(setId(id)),
})

export default connect(mapState, mapDispatch)(withRouter(CheckoutForm))
