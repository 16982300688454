export default {
  // Buttons
  'button.add': 'Ajouter',
  'button.automatic_redirect': "Cliquez ici si vous n'êtes pas redirigé(e) automatiquement.",
  'button.checkout_next': 'Poursuivre la commande',
  'button.disclaimer': 'Clause de non-responsabilité',
  'button.download_tickets': 'Télécharger des billets',
  'button.order_edit': 'Modifier la commande',
  'button.order_new': 'Nouvelle commande',
  'button.park_regulations': 'Règlement du parc',
  'button.payment_submit': 'Finaliser la commande',
  'button.privacy_statement': 'Déclaration de confidentialité',
  'button.remove': 'Supprimer',
  'button.to_products': 'Voir les billets',
  'button.voucher_redeem': 'Contrôler',
  'button.voucher_additional': 'Ajouter un code',

  // Errors
  'error.api.timeframe_expired': "L'une des plages horaires choisies est désormais fermée. Sélectionnez un autre créneau horaire",
  'error.api.timeframe_full': 'Un des créneaux horaires choisis est malheureusement complet. Veuillez en sélectionner un autre',
  'error.api.unexpected': 'Une erreur est survenue. Réessayez ultérieurement ou contactez-nous par téléphone au {phoneNumber}',
  'error.api.validation_failed': 'Il y a un problème avec votre commande. Réessayez ultérieurement ou contactez-nous par téléphone au {phoneNumber}',
  'error.checkout_invalid': 'Votre commande n’est pas encore complète. Modifiez votre commande puis confirmez-la de nouveau.',
  'error.max_tickets_reached': 'Vous avez atteint le nombre de billets maximum par personne.',
  'error.max_timeframe_reached': 'Le nombre maximum de billets disponibles pour ce créneau horaire est atteint.',
  'error.max_tickets_exceeded': 'Vous avez dépassé le nombre maximum de billets.',
  'error.max_tickets_product_quantity': 'FR: Vous avez atteint le nombre maximum de billets pour {title}.',
  'error.order_confirmation_failed': "Une erreur s'est produite lors de la confirmation de commande",
  'error.paymentmethods_fetch_failed': "Une erreur s'est produite lors de l'affichage des modes de paiement.",
  'error.products_fetch_failed': "Une erreur s'est produite lors de l’affichage des produits. Réessayez ou contactez-nous au {phoneNumber}.",
  'error.voucher_redeem_failed': "Le code du coupon communiqué n'est malheureusement pas valide.",
  'error.voucher_already_exists': 'Vous avez déjà utilisé ce code',
  'error.voucher_validation_failed': 'Une erreur est survenue lors de la validation de votre code',

  // Forms
  'form.customer.subtitle': 'Les informations ci-dessous nous sont nécessaires pour vous envoyer vos billets électroniques.',
  'form.customer.title': 'Informations personnelles',

  // Form help
  'help.email': 'Vos billets seront envoyés à cette adresse e-mail. Non reçu, vérifiez votre courrier indésirable.',

  // Form labels
  'label.agree_with': 'Vous devez accepter (le/la/les…)',
  'label.almost_sold_out': 'Presque complet',
  'label.and': 'et',
  'label.closed': 'Fermé',
  'label.email': 'Adresse électronique',
  'label.email_verify': 'Adresse électronique (Vérification)',
  'label.fields_required': '* Ces champs sont obligatoires.',
  'label.first_name': 'Prénom',
  'label.last_name': 'Nom de famille',
  'label.legend': 'Légende',
  'label.newsletter':
    '{name} peut me tenir informé(e) des dernières promotions, aventures, événements et avantages personnalisés en fonction de ma visite et de mes centres d’intérêt.',
  'label.park_regulations': 'Règlement du parc',
  'label.phone': 'Numéro de téléphone',
  'label.privacy_policy': 'Déclaration de confidentialité',
  'label.selected_day': 'Jour sélectionné',
  'label.sold_out': 'Complet',
  'label.terms': 'Conditions générales',
  'label.timeframe_from_util': 'Entre {from} & {until} heure.',
  'label.today': "Aujourd'hui",
  'label.voucher_code': 'Code du coupon',

  'placeholder.voucher': 'Votre code du coupon',

  // Tickets
  'tickets.arrangements.title': 'Arrangements disponibles sur',
  'tickets.activities.title': 'Activités disponibles pour',
  'tickets.activities.error': "Une erreur s'est produite lors de l’affichage des activité ({expositionId}). Réessayez ou contactez-nous au {phoneNumber}.",

  // Routes
  'route.checkout': '/fr/checkout',
  'route.payment_failed_beeksebergen': '/fr/message-derreur',
  'route.payment_failed_speelland': '/fr/message-derreur',
  'route.payment_success_beeksebergen': '/fr/merci-pour-votre-commande',
  'route.payment_success_speelland': '/fr/merci-pour-votre-commande',

  // Titles
  'title.404': 'Page introuvable',
  'title.404_subtitle':
    "Cette page n'existe pas (ou plus), ou il y a une faute de frappe dans l'adresse. Veuillez vérifier si l'adresse est correctement orthographiée.",
  'title.additional_products': 'Suppléments',
  'title.arrival_date': "Sélectionnez votre date d'arrivée",
  'title.checkout_redirect': 'Vérifier le commande.',
  'title.checkout_redirect_subtitle': 'Nous vérifions votre commande et vous redirigerons ensuite vers le site Web.',
  'title.free': 'Gratuit',
  'title.libema_footer': 'Venez vivre les plus belles aventures à {account}, du groupe',
  'title.order_verification_cancelled': 'Oups, cela prend trop de temps.',
  'title.order_verification_cancelled_subtitle':
    "Votre paiement a été reçu, mais votre commande n'a pas encore été traitée. Cela prend plus de temps que d'habitude. Si vous ne recevez pas d'e-mail dans quelques minutes, veuillez nous contacter au +31 (0)88-9000360.",
  'title.payment_failed': "Oups.. Une erreur s'est produite !",
  'title.payment_failed_subtitle':
    "Nous regrettons de vous informer qu'une erreur s'est produite. Merci de réessayer de nouveau ! Si vous avez besoin d'aide, appelez-nous au +31 (0)88-90 00 360 (jusqu'à 18h00 du lundi au vendredi, et jusqu'à 17h00 le samedi et le dimanche).",
  'title.payment_method': 'Choisissez votre mode de paiement',
  'title.payment_method_subtitle':
    "Il ne vous reste plus qu’à régler votre commande pour finaliser votre réservation. Sélectionnez l'un des modes de paiement.",
  'title.selected_date': 'Billets disponibles sur',
  'title.shopping_cart': 'Votre panier',
  'title.tickets': 'Commandez vos billets',
  'title.tickets_subtitle_beeksebergen':
    "Vous êtes prêts à partir en safari ? L'entrée est gratuite pour les enfants jusqu'à 3 ans. <br />Après 3 ans, vous pouvez profiter de nos tarifs préférentiels en ligne.",
  'title.tickets_subtitle_speelland':
    "Vous êtes prêt à passer une journée pleine d'amusement ? L'entrée est gratuite pour les enfants jusqu'à 3 ans. Après 3 ans, vous pouvez profiter de nos tarifs préférentiels en ligne.",
  'title.timeframes': "Heure d'arrivée",
  'title.timeframes_subtitle':
    "Vous trouverez ci-dessous l'heure à laquelle peut débuter votre aventure selon le jour sélectionné. L'heure d'arrivée la plus tardive possible est minimum une demi-heure avant la fermeture du parc.",
  'title.total': 'Total',
  'title.unfinished_order':
    "Malheureusement, il n'est pas possible de passer plusieurs commandes en même temps. Complétez d'abord l'autre commande ou cliquez sur 'Nouvelle commande'. L'autre commande sera alors annulée.",
  'title.vouchers': 'Billets avec coupons',
  'title.vouchers_subtitle': 'Si vous avez reçu un coupon, veuillez en renseigner le code ci-dessous.',
  'title.your_order': 'Votre commande',
  'title.your_order_subtitle':
    'Tu as presque fini! Mais pas encore ... Vérifiez votre commande, complétez votre commande et recevez-la directement dans votre boîte de réception.',

  // Form validators
  'validator.email_invalid': 'Adresse mail non valide',
  'validator.email_mismatch': 'Les adresses e-mail ne correspondent pas',
  'validator.email_required': 'Le champ Adresse mail est obligatoire.',
  'validator.first_name_required': 'Le champ Prénom est obligatoire.',
  'validator.ideal_issuer_required': 'Pour utiliser le mode de paiement iDEAL, veuillez sélectionner votre banque.',
  'validator.last_name_required': 'Le champ Nom de famille est obligatoire.',
  'validator.payment_method_required': 'Vous devez sélectionner un mode de paiement.',
  'validator.privacy_required': 'Vous devez accepter la Déclaration de confidentialité.',
  'validator.products_required': "Vous n'avez sélectionné aucun produit.",
  'validator.required': 'Ce champ est obligatoire',
  'validator.terms_required': 'Veuillez accepter les conditions générales',
  'validator.phone_required': 'Le champ numéro de téléphone est obligatoire',

  // Warnings
  'warning.no_products': 'Aucun produit sélectionné',
  'warning.no_timeframes': 'Cette journée n’a malheureusement plus aucune disponibilité. Choisissez vite un autre jour.',
  'warning.old_browser':
    'Vous utilisez un navigateur <strong>obsolète</strong>. Veuillez <a>actualiser votre navigateur</a> pour améliorer votre expérience et votre sécurité.',

  // Somerdrome
  'tickets.timeframe.tabs_day': 'Billets de jour',
  'tickets.timeframe.tabs_evening': 'Billets de soirée',
  'tickets.timeframe_v2.tabs_day': '<strong>Entrée de jour</strong>',
  'tickets.timeframe_v2.tabs_evening': '<strong>Entrée midi</strong>',
  'tickets.timeframe.tabs_day.subtitle': 'Accès depuis 10h00',
  'tickets.timeframe.tabs_evening.subtitle': 'Accès depuis 15h00',
  'tickets.timeframe.tabs_day.price': '<strong>À partir de € 18,00</strong>',
  'tickets.timeframe.tabs_evening.price': '<strong>€ 17,50</strong>',
  'tickets.timeframe.tabs_evening.price_beeksebergen': '<strong>€ 16,50</strong>',
  'somerdrome.day.timeframes_subtitle':
    "Vous pouvez choisir votre heure d'arrivée (entre <strong>10h00</strong> et <strong>15h00</strong>) puis rester au Safaripark Beekse Bergen jusqu'à <strong>21h00</strong>.",
  'somerdrome.evening.timeframes_subtitle':
    "Vous pouvez choisir votre heure d'arrivée (entre <strong>15h00</strong> et <strong>20h00</strong>) puis rester au Safaripark Beekse Bergen jusqu'à <strong>21h00</strong>.",

  // Order Confirmation (BeekseBergen)
  'order_confirmation.success.banner.title': "Pour partir bien préparé à l'aventure",
  'order_confirmation.success.banner.subtitle':
    "Téléchargez d’ores et déjà l'appli Beekse Bergen. Vous y trouverez toutes les informations sur l’univers de Beekse Bergen et aurez ainsi toujours le plan du parc à portée de main.",
  'order_confirmation.success.banner.button': "Découvrir l'appli",
  'order_confirmation.success.banner.button_url': 'https://www.beeksebergen.nl/fr/app',

  // Order Confirmation (BrewAtTheZoo)
  'order_confirmation.brewatthezoo.success.title': 'Gelukt! Je bent erbij!',
  'order_confirmation.brewatthezoo.success.subtitle': `<div>
  <p style="margin-bottom: 8px">
    Heb jij ook zo'n zin in Brew@theZoo bij Safaripark Beekse Bergen? Om goed voorbereid op 'biersafari' te gaan, hebben wij nog een paar
    belangrijke punten op een rijtje gezet:
  </p>
  <ol style="list-style:decimal;margin-bottom:8px;padding-left:24px">
    <li>
      Je ontvangt je gekochte Brew@theZoo tickets en reserveringsbevestiging op het opgegeven e-mailadres. Let op: mocht je jouw tickets
      niet direct ontvangen, controleer dan of deze in je spam of ongewenste e-mail terecht zijn gekomen.
    </li>
    <li>Neem je tickets én reserveringsbevestiging mee naar het evenement.</li>
    <li>Heb je tickets met korting gekocht? Zorg dan dat je je abonnement of Attractiepas ook meeneemt.</li>
    <li>Op de hoogte blijven van het laatste nieuws in Beekse Bergen? Schrijf je in voor de <a href="https://www.beeksebergen.nl/nieuwsbrief">nieuwsbrief</a>.</li>
  </ol>
  <p style="margin-bottom: 8px">
    We gaan ons best doen om weer een onvergetelijke editie van Brew@theZoo neer te zetten samen met jou. Tot dan, proost!
  </p>
</div>`,
  'order_confirmation.brewatthezoo.success.banner.title': 'Blijven slapen tijdens Brew@theZoo?',
  'order_confirmation.brewatthezoo.success.banner.subtitle':
    'Dat kan! Overnacht tussen de wilde dieren op het Safari Resort, midden in de natuur op het Lake Resort of met hotelservice in het nieuwe Safari Hotel.',
  'order_confirmation.brewatthezoo.success.banner.button': 'Bekijk alle accommodaties',
  'order_confirmation.brewatthezoo.success.banner.button_url': 'https://www.beeksebergen.nl/overnachten/zoek-en-boek',
}
