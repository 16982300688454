const tld = process.env.NODE_ENV === 'production' ? 'nl' : 'test'

export enum ACCOUNT_NAMES {
  KLIMRIJK = 'klimrijk',
  BEEKSEBERGEN = 'beeksebergen',
  LIGHTSAFARI = 'lightsafari',
  AVIODROME = 'aviodrome',
  AQUAZOO = 'aquazoo',
  DIERENRIJK = 'eindhovenzoo',
  ZOOPARC = 'zooparc',
  SPEELLAND = 'speelland',
}

const accounts = {
  '654d85c9-1658-417c-ac31-606129a7c1dd': {
    id: '654d85c9-1658-417c-ac31-606129a7c1dd',
    name: ACCOUNT_NAMES.AVIODROME,
    title: 'Aviodrome',
    email: 'info@aviodrome.nl',
    domain: `tickets.aviodrome.${tld}`,
    phone_number: '+31 (0) 320 - 289842',
    redirectDomain: 'www.aviodrome.nl',
    languages: ['nl', 'en'],
    gtm_id: 'GTM-K2WP5R',
    gtm_affiliation: 'Aviodrome - Webshop',
    cookiebot_id: 'cbb4e7bc-ad6e-4507-bf4a-1372ecfb1b61',
    timeFrameAlmostSoldOutThreshold: 20,
    disclaimer: {
      nl: 'https://www.aviodrome.nl/disclaimer/',
      en: 'https://www.aviodrome.nl/en-disclaimer/',
    },
    parkregulations: {
      nl: 'https://www.aviodrome.nl/informatie/parkreglement/',
      en: 'https://www.aviodrome.nl/en-park-regulations/',
    },
    privacystatement: {
      nl: 'https://www.aviodrome.nl/privacy-statement',
      en: 'https://www.aviodrome.nl/en-privacy-statement/',
    },
    terms: {
      nl: 'https://www.aviodrome.nl/informatie/algemene-voorwaarden',
      en: 'https://www.aviodrome.nl/informatie/en-terms-and-conditions/',
    },
    theme: {
      color_neutral_dark: '#454c9a',
      color_neutral_medium: '#454c9a',
      color_neutral_light: '#eeeff1',
      color_primary: '#e7431a',
      color_secondary: '#ffcb00',
      font_default: 'Trebuchet MS, -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      text_color_default: '#1b2140',
      text_color_muted: '#777777',
      logo: 'https://d2qksnoxbl01xg.cloudfront.net/aviodrome/logo.svg',
    },
  },
  '476125a4-c694-4fef-8f0f-fe28bd58cda4': {
    id: '476125a4-c694-4fef-8f0f-fe28bd58cda4',
    name: ACCOUNT_NAMES.AQUAZOO,
    title: 'Aquazoo',
    email: 'info@aquazoo.nl',
    domain: `tickets.aquazoo.${tld}`,
    phone_number: '+31 (0) 511 – 431214',
    redirectDomain: 'www.aquazoo.nl',
    languages: ['nl', 'en', 'de'],
    gtm_id: 'GTM-MXXKR5B',
    gtm_affiliation: 'AquaZoo Leeuwarden – Shop',
    cookiebot_id: '009f69c9-ffa2-4b50-b019-0b4fe15389d5',
    timeFrameAlmostSoldOutThreshold: 20,
    disclaimer: {
      nl: 'https://www.aquazoo.nl/disclaimer/',
      en: 'https://www.aquazoo.nl/en-disclaimer/',
      de: 'https://www.aquazoo.nl/de-disclaimer/',
    },
    parkregulations: {
      nl: 'https://www.aquazoo.nl/parkreglement',
      en: 'https://www.aquazoo.nl/en-park-regulations/',
      de: 'https://www.aquazoo.nl/de-parkvorschriften/',
    },
    privacystatement: {
      nl: 'https://www.aquazoo.nl/privacy-statement',
      en: 'https://www.aquazoo.nl/en-privacy-statement/',
      de: 'https://www.aquazoo.nl/de-privacy-statement/',
    },
    terms: {
      nl: 'https://www.aquazoo.nl/algemene-voorwaarden',
      en: 'https://www.aquazoo.nl/en-terms-and-conditions/',
      de: 'https://www.aquazoo.nl/de-allgemeine-geschaftsbedingungen/',
    },
    theme: {
      color_neutral_dark: '#5d7261',
      color_neutral_medium: '#5d7261',
      color_primary: '#1c355e',
      color_tertiary: '#cf8a00',
      color_secondary: '#cf8a00',
      font_default: 'Source Sans Pro,sans-serif',
      logo: 'https://d2qksnoxbl01xg.cloudfront.net/aquazoo/logo.svg',
    },
  },
  '0050cfc5-c141-462d-b0ae-86b2cf301e79': {
    id: '0050cfc5-c141-462d-b0ae-86b2cf301e79',
    name: ACCOUNT_NAMES.BEEKSEBERGEN,
    title: 'Safaripark',
    email: 'tickets@beeksebergen.nl',
    phone_number: '+31 (0) 88 - 9000321',
    domain: `tickets.beeksebergen.${tld}`,
    redirectDomain: 'www.beeksebergen.nl',
    languages: ['nl', 'en', 'de', 'fr'],
    gtm_id: 'GTM-KT4P7NK',
    gtm_affiliation: 'Safaripark Beekse Bergen – Shop',
    cookiebot_id: 'e2b4da79-8b45-4947-96d0-27885f6ce36f',
    optimize_id: 'OPT-ND6X2TV',
    timeFrameAlmostSoldOutThreshold: 20,
    disclaimer: {
      nl: 'https://www.beeksebergen.nl/disclaimer',
      en: 'https://www.beeksebergen.nl/en/disclaimer',
      de: 'https://www.beeksebergen.nl/de/disclaimer',
      fr: 'https://www.beeksebergen.nl/fr/clause-de-non-responsabilite',
    },
    parkregulations: {
      nl: 'https://www.beeksebergen.nl/parkreglement/safaripark',
      en: 'https://www.beeksebergen.nl/en/park-regulations/safaripark',
      de: 'https://www.beeksebergen.nl/de/park-richtlinien/park-richtlinien-des-safariparks',
      fr: 'https://www.beeksebergen.nl/fr/reglement-du-parc/reglement-du-safaripark',
    },
    privacystatement: {
      nl: 'https://www.beeksebergen.nl/privacy-statement',
      en: 'https://www.beeksebergen.nl/en/privacy-statement',
      de: 'https://www.beeksebergen.nl/de/datenschutzerklarung',
      fr: 'https://www.beeksebergen.nl/fr/declaration-de-confidentialite',
    },
    terms: {
      nl: 'https://www.beeksebergen.nl/algemene-voorwaarden-safaripark',
      en: 'https://www.beeksebergen.nl/en/terms-and-conditions/safaripark',
      de: 'https://www.beeksebergen.nl/de/allgemeine-geschaftsbedingungen/safaripark',
      fr: 'https://www.beeksebergen.nl/fr/conditions-generales/safaripark',
    },
    theme: {
      color_neutral_dark: '#a48a76', // Dark Sand
      color_neutral_medium: '#596831',
      color_neutral_light: '#f6f1e6', // Sand
      color_primary: '#e84d0e', // Orange
      color_secondary: '#fcce4c', // Yellow
      font_default: 'sofia-pro, "Century Gothic", sans-serif',
      text_color_default: '#292b2c', // Dark (Blackish)
      text_color_muted: '#5c6738', // Green
      logo: 'https://d2qksnoxbl01xg.cloudfront.net/beeksebergen/logo.svg',
    },
  },
  // Unfortunately, light safari test and production don't share the same Id like
  // the other brands.
  '668EEABD-F671-4C19-BC71-EA640C25E833': {
    id: '668EEABD-F671-4C19-BC71-EA640C25E833',
    // Testing
    useArrangements: true,
    name: ACCOUNT_NAMES.LIGHTSAFARI,
    title: 'Safaripark',
    email: 'tickets@beeksebergen.nl',
    phone_number: '+31 (0) 88 - 9000321',
    domain: `tickets.beeksebergen.${tld}`,
    redirectDomain: 'www.beeksebergen.nl',
    languages: ['nl', 'en', 'de', 'fr'],
    gtm_id: 'GTM-KT4P7NK',
    gtm_affiliation: 'Safaripark Beekse Bergen – Shop',
    cookiebot_id: 'e2b4da79-8b45-4947-96d0-27885f6ce36f',
    optimize_id: 'OPT-ND6X2TV',
    timeFrameAlmostSoldOutThreshold: 1000,
    disclaimer: {
      nl: 'https://www.beeksebergen.nl/disclaimer',
      en: 'https://www.beeksebergen.nl/en/disclaimer',
      de: 'https://www.beeksebergen.nl/de/disclaimer',
      fr: 'https://www.beeksebergen.nl/fr/clause-de-non-responsabilite',
    },
    parkregulations: {
      nl: 'https://www.beeksebergen.nl/parkreglement/safaripark',
      en: 'https://www.beeksebergen.nl/en/park-regulations/safaripark',
      de: 'https://www.beeksebergen.nl/de/park-richtlinien/park-richtlinien-des-safariparks',
      fr: 'https://www.beeksebergen.nl/fr/reglement-du-parc/reglement-du-safaripark',
    },
    privacystatement: {
      nl: 'https://www.beeksebergen.nl/privacy-statement',
      en: 'https://www.beeksebergen.nl/en/privacy-statement',
      de: 'https://www.beeksebergen.nl/de/datenschutzerklarung',
      fr: 'https://www.beeksebergen.nl/fr/declaration-de-confidentialite',
    },
    terms: {
      nl: 'https://www.beeksebergen.nl/algemene-voorwaarden-safaripark',
      en: 'https://www.beeksebergen.nl/en/terms-and-conditions/safaripark',
      de: 'https://www.beeksebergen.nl/de/allgemeine-geschaftsbedingungen/safaripark',
      fr: 'https://www.beeksebergen.nl/fr/conditions-generales/safaripark',
    },
    theme: {
      color_neutral_dark: '#2b2b2b', // Dark Sand
      color_neutral_medium: '#2b2b2b',
      color_neutral_light: '#00fd5a', // Sand
      color_primary: '#00fd5a', // Orange
      color_secondary: '#00fd5a', // Yellow
      font_default: 'sofia-pro, "Century Gothic", sans-serif',
      text_color_default: 'black', // Dark (Blackish)
      text_color_muted: '#5c6738', // Green
      logo: 'https://lightsafari.beeksebergen.nl/images/lightSafari/logo.svg',
    },
  },
  '01F3F92D-0673-44D6-8E86-37F2C8D180D2': {
    id: '01F3F92D-0673-44D6-8E86-37F2C8D180D2',
    useArrangements: true,
    name: ACCOUNT_NAMES.LIGHTSAFARI,
    title: 'Safaripark',
    email: 'tickets@beeksebergen.nl',
    phone_number: '+31 (0) 88 - 9000321',
    domain: `tickets.beeksebergen.${tld}`,
    redirectDomain: 'www.beeksebergen.nl',
    languages: ['nl', 'en', 'de', 'fr'],
    gtm_id: 'GTM-KT4P7NK',
    gtm_affiliation: 'Safaripark Beekse Bergen – Shop',
    cookiebot_id: 'e2b4da79-8b45-4947-96d0-27885f6ce36f',
    optimize_id: 'OPT-ND6X2TV',
    timeFrameAlmostSoldOutThreshold: 1000,
    disclaimer: {
      nl: 'https://www.beeksebergen.nl/disclaimer',
      en: 'https://www.beeksebergen.nl/en/disclaimer',
      de: 'https://www.beeksebergen.nl/de/disclaimer',
      fr: 'https://www.beeksebergen.nl/fr/clause-de-non-responsabilite',
    },
    parkregulations: {
      nl: 'https://www.beeksebergen.nl/parkreglement/safaripark',
      en: 'https://www.beeksebergen.nl/en/park-regulations/safaripark',
      de: 'https://www.beeksebergen.nl/de/park-richtlinien/park-richtlinien-des-safariparks',
      fr: 'https://www.beeksebergen.nl/fr/reglement-du-parc/reglement-du-safaripark',
    },
    privacystatement: {
      nl: 'https://www.beeksebergen.nl/privacy-statement',
      en: 'https://www.beeksebergen.nl/en/privacy-statement',
      de: 'https://www.beeksebergen.nl/de/datenschutzerklarung',
      fr: 'https://www.beeksebergen.nl/fr/declaration-de-confidentialite',
    },
    terms: {
      nl: 'https://www.beeksebergen.nl/algemene-voorwaarden-safaripark',
      en: 'https://www.beeksebergen.nl/en/terms-and-conditions/safaripark',
      de: 'https://www.beeksebergen.nl/de/allgemeine-geschaftsbedingungen/safaripark',
      fr: 'https://www.beeksebergen.nl/fr/conditions-generales/safaripark',
    },
    theme: {
      color_neutral_dark: '#2b2b2b', // Dark Sand
      color_neutral_medium: '#2b2b2b',
      color_neutral_light: '#00fd5a', // Sand
      color_primary: '#00fd5a', // Orange
      color_secondary: '#00fd5a', // Yellow
      font_default: 'sofia-pro, "Century Gothic", sans-serif',
      text_color_default: 'black', // Dark (Blackish)
      text_color_muted: '#5c6738', // Green
      logo: 'https://lightsafari.beeksebergen.nl/images/lightSafari/logo.svg',
    },
  },
  '3393508d-bdd6-4115-971a-9fc2e53575fa': {
    id: '3393508d-bdd6-4115-971a-9fc2e53575fa',
    name: ACCOUNT_NAMES.DIERENRIJK,
    title: 'Eindhoven Zoo',
    email: 'info@eindhovenzoo.nl',
    domain: `vouchers.eindhovenzoo.${tld}`,
    phone_number: '+31 (0) 492 - 668240',
    redirectDomain: 'www.eindhovenzoo.nl',
    languages: ['nl', 'en', 'de'],
    gtm_id: 'GTM-MF2RRS',
    gtm_affiliation: 'EindhovenZoo – Webshop',
    cookiebot_id: '476e2e7a-65ca-45c5-b99c-25d46f0fe197',
    timeFrameAlmostSoldOutThreshold: 20,
    disclaimer: {
      nl: 'https://www.eindhovenzoo.nl/disclaimer/',
      en: 'https://www.eindhovenzoo.nl/en-disclaimer/',
      de: 'https://www.eindhovenzoo.nl/de-disclaimer/',
    },
    parkregulations: {
      nl: 'https://www.eindhovenzoo.nl/park/parkreglement',
      en: 'https://www.eindhovenzoo.nl/en-park-regulations',
      de: 'https://www.eindhovenzoo.nl/de-parkvorschriften',
    },
    privacystatement: {
      nl: 'https://www.eindhovenzoo.nl/privacy-statement',
      en: 'https://www.eindhovenzoo.nl/en-privacy-statement/',
      de: 'https://www.eindhovenzoo.nl/de-privacy-statement/',
    },
    terms: {
      nl: 'https://www.eindhovenzoo.nl/park/algemene-voorwaarden',
      en: 'https://www.eindhovenzoo.nl/park/en-terms-and-conditions/',
      de: 'https://www.eindhovenzoo.nl/park/de-allgemeine-geschaftsbedingungen/',
    },
    theme: {
      color_neutral_dark: '#003626',
      color_neutral_medium: '#003626',
      button_border_radius: '8px',
      color_neutral_light: '#fff0de',
      color_primary: '#ff8500',
      color_secondary: '#ff8500',
      font_default: '"Radio Canada", sans-serif',
      text_color_default: '#003626',
      text_color_muted: '#5c6738',
      logo: 'https://d2qksnoxbl01xg.cloudfront.net/dierenrijk/logo_ez.svg',
    },
  },
  'e638ca1b-687a-4218-b096-0299e99db7bb': {
    id: 'e638ca1b-687a-4218-b096-0299e99db7bb',
    name: ACCOUNT_NAMES.KLIMRIJK,
    headerAlert: {
      text: 'alert.closed.winter.season',
      link: {
        en: 'mailto:info@libemafunfactory.nl',
        nl: 'https://www.klimrijk.nl/winter-activiteiten',
      },
      startDate: '2022-11-30',
      endDate: '2023-02-28',
    },
    title: 'Klimrijk',
    email: 'info@klimrijk.nl',
    phone_number: '+31 (0) 40 - 2556545',
    domain: `tickets.klimrijk.${tld}`,
    redirectDomain: 'www.klimrijk.nl',
    languages: ['nl'],
    gtm_id: 'GTM-P64PKS',
    gtm_affiliation: 'Klimijk – Shop',
    cookiebot_id: '49e9ed5e-78af-48c7-90cd-27fe4afa23ee',
    timeFrameAlmostSoldOutThreshold: 4,
    calendarInfoMessage: {
      key: 'tickets.calendar.info',
      links: {
        nl: {
          email: {
            href: 'mailto:info@libemafunfactory.nl',
            title: 'info@libemafunfactory.nl',
          },
        },
        en: {
          email: {
            href: 'mailto:info@libemafunfactory.nl',
            title: 'info@libemafunfactory.nl',
          },
        },
      },
    },
    disclaimer: {
      nl: 'https://www.klimrijk.nl/disclaimer',
      en: 'https://www.klimrijk.nl/en/disclaimer',
    },
    parkregulations: {
      nl: 'https://www.klimrijk.nl/parkreglement',
      en: 'https://www.klimrijk.nl/en/park-regulations',
    },
    privacystatement: {
      nl: 'https://www.klimrijk.nl/privacy-statement',
      en: 'https://www.klimrijk.nl/en/privacy-statement',
    },
    terms: {
      nl: 'https://www.klimrijk.nl/algemene-voorwaarden',
      en: 'https://www.klimrijk.nl/en/general-terms-and-conditions',
    },
    theme: {
      color_neutral_dark: '#7B7872',
      color_neutral_medium: '#235809',
      color_neutral_light: '#f5f3f1',
      color_primary: '#235809',
      color_secondary: '#FDC300',
      font_default: '"Open Sans", Helvetica, Arial, sans-serif',
      text_color_default: '#292b2c',
      text_color_muted: '#5c6738',
      logo: 'https://d2qksnoxbl01xg.cloudfront.net/klimrijk/logo.png',
    },
  },
  '98735cb2-9ef3-47f6-9b17-5080deef20a0': {
    id: '98735cb2-9ef3-47f6-9b17-5080deef20a0',
    name: ACCOUNT_NAMES.SPEELLAND,
    title: 'Speelland',
    email: 'tickets@beeksebergen.nl',
    phone_number: '+31 (0) 88 - 9000321',
    domain: `tickets-speelland.beeksebergen.${tld}`,
    redirectDomain: 'www.beeksebergen.nl',
    languages: ['nl', 'en', 'de', 'fr'],
    gtm_id: 'GTM-KT4P7NK',
    gtm_affiliation: 'Speelland Beekse Bergen - Shop',
    cookiebot_id: 'e2b4da79-8b45-4947-96d0-27885f6ce36f',
    timeFrameAlmostSoldOutThreshold: 20,
    disclaimer: {
      nl: 'https://www.beeksebergen.nl/disclaimer',
      en: 'https://www.beeksebergen.nl/en/disclaimer',
      de: 'https://www.beeksebergen.nl/de/disclaimer',
      fr: 'https://www.beeksebergen.nl/fr/clause-de-non-responsabilite',
    },
    parkregulations: {
      nl: 'https://www.beeksebergen.nl/parkreglement/speelland',
      en: 'https://www.beeksebergen.nl/en/park-regulations/speelland',
      de: 'https://www.beeksebergen.nl/de/park-richtlinien/park-richtlinien-speelland',
      fr: 'https://www.beeksebergen.nl/fr/reglement-du-parc/reglement-du-speelland',
    },
    privacystatement: {
      nl: 'https://www.beeksebergen.nl/privacy-statement',
      en: 'https://www.beeksebergen.nl/en/privacy-statement',
      de: 'https://www.beeksebergen.nl/de/datenschutzerklarung',
      fr: 'https://www.beeksebergen.nl/fr/declaration-de-confidentialite',
    },
    terms: {
      nl: 'https://www.beeksebergen.nl/algemene-voorwaarden-speelland',
      en: 'https://www.beeksebergen.nl/en/terms-and-conditions/speelland',
      de: 'https://www.beeksebergen.nl/de/allgemeine-geschaftsbedingungen/speelland',
      fr: 'https://www.beeksebergen.nl/fr/conditions-generales/speelland',
    },
    theme: {
      color_neutral_dark: '#a48a76', // Dark Sand
      color_neutral_medium: '#596831',
      color_neutral_light: '#f6f1e6', // Sand
      color_primary: '#e84d0e', // Orange
      color_secondary: '#fcce4c', // Yellow
      font_default: 'sofia-pro, "Century Gothic", sans-serif',
      text_color_default: '#292b2c', // Dark (Blackish)
      text_color_muted: '#5c6738', // Green
      logo: 'https://d2qksnoxbl01xg.cloudfront.net/speelland/logo.svg',
    },
  },
  '00eefa14-1428-444e-b131-cdcd178e9809': {
    id: '00eefa14-1428-444e-b131-cdcd178e9809',
    name: ACCOUNT_NAMES.ZOOPARC,
    title: 'Zooparc',
    email: 'info@zooparcoverloon.nl',
    domain: `tickets.zooparc.${tld}`,
    phone_number: '+31 (0) 478 – 640046',
    redirectDomain: 'www.zooparc.nl',
    languages: ['nl', 'en', 'de'],
    gtm_id: 'GTM-THM38H',
    gtm_affiliation: 'ZooParc Overloon - Shop',
    cookiebot_id: '44755584-e365-41b9-bdba-43cf31a4b93e',
    timeFrameAlmostSoldOutThreshold: 20,
    disclaimer: {
      nl: 'https://www.zooparc.nl/disclaimer/',
      en: 'https://www.zooparc.nl/en-disclaimer/',
      de: 'https://www.zooparc.nl/de-disclaimer/',
    },
    parkregulations: {
      nl: 'https://www.zooparc.nl/parkreglement',
      en: 'https://www.zooparc.nl/en-park-regulations',
      de: 'https://www.zooparc.nl/de-parkvorschriften',
    },
    privacystatement: {
      nl: 'https://www.zooparc.nl/privacy-statement',
      en: 'https://www.zooparc.nl/en-privacy-statement/',
      de: 'https://www.zooparc.nl/de-privacy-statement/',
    },
    terms: {
      nl: 'https://www.zooparc.nl/algemene-voorwaarden',
      en: 'https://www.zooparc.nl/en-terms-and-conditions/',
      de: 'https://www.zooparc.nl/de-allgemeine-geschaftsbedingungen/',
    },
    theme: {
      color_neutral_dark: '#513d38',
      color_neutral_medium: '#985a39',
      color_neutral_light: '#fef2e0',
      color_primary: '#92c045',
      color_secondary: '#ee5933',
      font_default: '-apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      text_color_default: '#222',
      text_color_muted: '#74633c',
      logo: 'https://d2qksnoxbl01xg.cloudfront.net/zooparc/logo.svg',
    },
  },
}

export const findByDomain = (domain: string) => {
  switch (domain) {
    case 'tickets.aviodrome.nl':
    case 'tickets.aviodrome.test':
    case 'tickets-testing.aviodrome.nl': {
      return accounts['654d85c9-1658-417c-ac31-606129a7c1dd']
    }
    case 'tickets.aquazoo.nl':
    case 'tickets.aquazoo.test':
    case 'tickets-testing.aquazoo.nl': {
      return accounts['476125a4-c694-4fef-8f0f-fe28bd58cda4']
    }
    case 'tickets.dierenrijk.nl':
    case 'tickets.dierenrijk.test':
    case 'vouchers.dierenrijk.nl':
    case 'tickets-testing.dierenrijk.nl':
    case 'vouchers.eindhovenzoo.nl':
    case 'vouchers.eindhovenzoo.test':
    case 'testing-voucher-eindhovenzoo.nl': {
      return accounts['3393508d-bdd6-4115-971a-9fc2e53575fa']
    }
    case 'tickets.klimrijk.nl':
    case 'tickets.klimrijk.test':
    case 'tickets-testing.klimrijk.nl': {
      return accounts['e638ca1b-687a-4218-b096-0299e99db7bb']
    }
    case 'tickets-speelland.beeksebergen.nl':
    case 'tickets-speelland.beeksebergen.test':
    case 'tickets-speelland-testing.beeksebergen.nl': {
      return accounts['98735cb2-9ef3-47f6-9b17-5080deef20a0']
    }
    case 'tickets.zooparc.nl':
    case 'tickets.zooparc.test':
    case 'tickets-testing.zooparc.nl': {
      return accounts['00eefa14-1428-444e-b131-cdcd178e9809']
    }
    case 'tickets-testing-lightsafari.beeksebergen.nl':
    case 'tickets-lightsafari.beeksebergen.test': {
      return accounts['668EEABD-F671-4C19-BC71-EA640C25E833']
    }
    case 'tickets-lightsafari.beeksebergen.nl': {
      return accounts['01F3F92D-0673-44D6-8E86-37F2C8D180D2']
    }
    case 'tickets.beeksebergen.nl':
    case 'tickets.beeksebergen.test':
    case 'tickets-testing.beeksebergen.nl':
    default: {
      return accounts['0050cfc5-c141-462d-b0ae-86b2cf301e79']
    }
  }
}

export default accounts
