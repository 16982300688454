import React from 'react'
import styled from 'styled-components'
import { Translate } from '@jstack/libema-design-system'

const NoTimeframesAlert = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  padding: ${({ theme }) => theme.padding.md};
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

export default () => (
  <NoTimeframesAlert data-class="alert-no-timeframes">
    <Translate id="warning.no_timeframes" />
  </NoTimeframesAlert>
)
