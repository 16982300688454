import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { get, post } from '.'

const validateCart = (data: object): AxiosPromise =>
  post({
    url: '/api/carts',
    data,
  })

export const postCart = (data: object, config: AxiosRequestConfig): AxiosPromise =>
  post({
    url: `${process.env.RAZZLE_API_URL}/user/carts`,
    data,
    ...config,
  })

export interface PaymentMethodOption {
  label: string
  issuerid: string
}

export interface PaymentMethod {
  label: string
  name: string
  options: PaymentMethodOption[]
}

export const getPaymentMethods = (accountId: string | undefined): AxiosPromise<PaymentMethod[]> => {
  // Get Payment methods for a specific brand
  const url = accountId 
    ? `${process.env.RAZZLE_API_URL}/accounts/${accountId}/paymentmethods`
    : `${process.env.RAZZLE_API_URL}/paymentmethods`;

  return get({
    url,
  })
}

export default {
  postCart,
  validateCart,
}
