export default {
  // Buttons
  'button.add': 'Toevoegen',
  'button.automatic_redirect': 'Klik hier wanneer je niet direct doorgestuurd wordt.',
  'button.back_to_referral': '< Terug naar {referral}',
  'button.checkout_next': 'Verder met bestellen',
  'button.disclaimer': 'Disclaimer',
  'button.download_tickets': 'Download tickets',
  'button.order_edit': 'Bestelling wijzigen',
  'button.order_new': 'Nieuwe bestelling',
  'button.park_regulations': 'Parkreglement',
  'button.payment_submit': 'Afronden',
  'button.privacy_statement': 'Privacy Statement',
  'button.remove': 'Verwijderen',
  'button.to_products': 'Naar de tickets',
  'button.voucher_redeem': 'Controleer',
  'button.voucher_additional': 'Nog een code',

  // Errors
  'error.api.timeframe_expired': 'Één van de gekozen tijdssloten is inmiddels gesloten. Selecteer een ander tijdsslot',
  'error.api.timeframe_full': 'Één van de gekozen tijdssloten is helaas vol. Selecteer een ander tijdsslot',
  'error.api.unexpected': 'Er is een fout opgetreden. Probeer het later opnieuw of neem contact op via {phoneNumber}',
  'error.api.validation_failed': 'Er is iets mis met je order. Probeer het later opnieuw of neem contact op via {phoneNumber}',
  'error.checkout_invalid': 'Je bestelling is nog niet volledig. Wijzig je bestelling en plaats hem opnieuw.',
  'error.max_tickets_reached': 'Je hebt het maximum aantal tickets per persoon bereikt.',
  'error.max_tickets_exceeded': 'Je hebt meer tickets dan is toegestaan.',
  'error.max_tickets_product_quantity': 'U heeft het maximale aantal tickets bereikt voor {title}',
  'error.max_timeframe_reached': 'Het maximum aantal tickets voor dit tijdsslot is bereikt.',
  'error.order_confirmation_failed': 'Er is een fout opgetreden tijdens de order bevestiging.',
  'error.paymentmethods_fetch_failed': 'Er is een fout opgetreden tijdens het opvragen van de betaalmethoden.',
  'error.products_fetch_failed': 'Er is een fout opgetreden tijdens het opvragen van de producten. Probeer het opnieuw of neem contact op via {phoneNumber}',
  'error.timeframes_fetch_failed':
    'Er is een fout opgetreden tijdens het opvragen van de beschikbare dagen. Probeer het opnieuw of neem contact op via {phoneNumber}',
  'error.voucher_redeem_failed': 'Deze kortingscode is helaas niet geldig.',
  'error.voucher_already_exists': 'Deze kortingscode heb je al ingevuld.',
  'error.voucher_validation_failed': 'Er is een fout opgetreden tijdens het valideren van je kortingscode.',
  'error.voucher_maximum_vouchers_reached': 'Je hebt het maximum aantal kortingscodes bereikt',

  // Forms
  'form.customer.subtitle': 'Onderstaande gegevens hebben wij nodig om jouw tickets digitaal te versturen.',
  'form.customer.title': 'Persoonlijke informatie',

  // Form help
  'help.email': 'Je tickets worden naar dit e-mailadres gestuurd. Niet ontvangen, controleer je ongewenste mail.',

  // Form labels
  'label.agree_with': 'Je dient akkoord te gaan met de',
  'label.almost_sold_out': 'Bijna vol',
  'label.and': 'en',
  'label.closed': 'Gesloten',
  'label.email': 'E-mail adres',
  'label.email_verify': 'E-mail adres (verificatie)',
  'label.fields_required': '* Deze velden zijn verplicht',
  'label.first_name': 'Voornaam',
  'label.last_name': 'Achternaam',
  'label.legend': 'Legenda',
  'label.newsletter':
    '{name} mag me op de hoogte houden van de laatste acties, avonturen, evenementen en persoonlijk voordeel n.a.v. mijn bezoek en interesses.',
  'label.park_regulations': 'parkreglement',
  'label.phone': 'Telefoonnummer',
  'label.privacy_policy': 'privacy statement',
  'label.selected_day': 'Geselecteerd',
  'label.sold_out': 'Vol',
  'label.terms': 'algemene voorwaarden',
  'label.timeframe_from_util': 'Tussen {from} & {until} uur',
  'label.today': 'Vandaag',
  'label.voucher_code': 'Kortingscode',

  'placeholder.voucher': 'Je kortingscode',

  // Tickets
  'tickets.arrangements.title': 'Beschikbare arrangementen op',
  'tickets.activities.title': 'Beschikbare activiteiten voor',
  'tickets.activities.error':
    'Er is een fout opgetreden tijdens het opvragen van de activiteiten ({expositionId}). Probeer het opnieuw of neem contact op via {phoneNumber}',
  'tickets.calendar.info':
    'Voor groepen groter dan 20 personen is het soms mogelijk om activiteiten te boeken op dagen die gesloten of volgeboekt zijn. Neem voor meer informatie contact met ons op via <b>088-9000360</b> of mail naar <a>email</a>. Vermeld je naam, telefoonnummer en wanneer je wilt komen met eventuele aankomsttijd.',

  // Routes
  'route.checkout': '/checkout',
  'route.payment_failed_aviodrome': '/tickets/foutmelding',
  'route.payment_failed_aquazoo': '/foutmelding',
  'route.payment_failed_beeksebergen': '/tickets/foutmelding',
  'route.payment_failed_dierenrijk': '/foutmelding',
  'route.payment_failed_klimrijk': '/foutmelding',
  'route.payment_failed_speelland': '/foutmelding',
  'route.payment_failed_zooparc': '/foutmelding',
  'route.payment_success_aviodrome': '/tickets/bedankt-voor-uw-aankoop',
  'route.payment_success_aquazoo': '/bedankt-voor-uw-aankoop',
  'route.payment_success_beeksebergen': '/bedankt-voor-uw-aankoop',
  'route.payment_success_dierenrijk': '/bedankt-voor-uw-aankoop',
  'route.payment_success_klimrijk': '/bedankt-voor-uw-aankoop',
  'route.payment_success_speelland': '/bedankt-voor-uw-aankoop',
  'route.payment_success_zooparc': '/bedankt-voor-uw-aankoop',

  // Titles
  'title.404': 'Pagina niet gevonden',
  'title.404_subtitle': 'Deze pagina bestaat niet (meer), of er zit een typfout in het adres. Controleer of het adres juist is gespeld',
  'title.additional_products': "Extra's",
  'title.another_voucher': 'Nog een kortingscode controleren',
  'title.arrival_date': 'Selecteer je aankomstdag',
  'title.checkout_redirect': 'Bestelling verifiëren',
  'title.checkout_redirect_subtitle': 'Wij verifiëren de bestelling en sturen je zo door naar de website.',
  'title.free': 'Gratis',
  'title.libema_footer': 'De mooiste tijd beleef je bij {account}, onderdeel van',
  'title.order_verification_cancelled': 'Oeps, dit duurt te lang.',
  'title.order_verification_cancelled_subtitle':
    'Je betaling is ontvangen, echter is je order nog niet verwerkt. Dit duurt langer dan gebruikelijk. Als je binnen enkele minuten geen e-mail ontvangt, neem dan contact met ons via 088-9000360',
  'title.payment_failed': 'Oeps... Er is iets mis gegaan!',
  'title.payment_failed_subtitle':
    'Helaas is er iets mis gegaan. Controleer of je jouw tickets per e-mail hebt ontvangen. Dit kan maximaal 30 minuten duren. Toch hulp nodig? Bel ons via 088-9000321 (ma-vrij tot 18.00 uur, za-zo tot 17.00 uur).',
  'title.payment_method': 'Kies je betaalmethode',
  'title.payment_method_subtitle': 'Om je boeking definitief te maken hoef je alleen nog maar af te rekenen. Selecteer een van de betaalmethoden.',
  'title.selected_date': 'Beschikbare tickets op',
  'title.shopping_cart': 'Je winkelmandje',
  'title.tickets': 'Bestel je tickets',
  'title.tickets_subtitle_aviodrome':
    "De tijd vliegt voorbij in Aviodrome! Kinderen tot 3 jaar 'vliegen' gratis naar binnen. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  'title.tickets_subtitle_aquazoo':
    "Ben jij klaar voor een spetterende dag in AquaZoo? Kinderen tot 3 jaar mogen gratis 'een duik nemen' in het park. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.",
  'title.tickets_subtitle_beeksebergen':
    'Ben je klaar om op safari te gaan? Kinderen tot 3 jaar krijgen gratis toegang. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.',
  'title.tickets_subtitle_dierenrijk':
    'Ontdek het dier in jezelf en kom naar Dierenrijk. Kinderen tot 3 jaar hangen gratis de beest uit. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.',
  'title.tickets_subtitle_speelland':
    'Ben je klaar voor een dag vol speelplezier? Kinderen tot 3 jaar krijgen gratis toegang. Ouder dan 3 jaar? Dan profiteer je van onze online korting.',
  'title.tickets_subtitle_zooparc':
    'Ben jij klaar om op expeditie te gaan? Kinderen tot 3 jaar mogen gratis het park verkennen. <br />Ouder dan 3 jaar? Dan profiteer je van onze online korting.',
  'title.timeframes': 'Aankomsttijd',
  'title.timeframes_subtitle':
    'Hieronder zie je wanneer jouw avontuur kan beginnen op de door jou geselecteerde dag. De uiterste aankomsttijd ligt minimaal een halfuur voor sluitingstijd van het park.',
  'title.total': 'Totaal',
  'title.unfinished_order':
    'We zien dat je al met een betaling bent gestart, hierdoor is het niet meer mogelijk om deze bestelling aan te passen. Rond deze eerst af of kies ‘Nieuwe bestelling’.',
  'title.voucher_products': 'Producten',
  'title.vouchers': 'Tickets met kortingscodes',
  'title.vouchers_validate': 'Valideer je kortingscode',
  'title.vouchers_subtitle':
    'Vul je kortingscodes hieronder in, bestel tickets met korting en ontvang deze in je mailbox. Vergeet je daadwerkelijke bezoek niet voorafgaand te reserveren via onze website.',
  'title.your_order': 'Jouw bestelling',
  'title.your_order_subtitle': 'We zijn er bijna! Maar nog niet helemaal… Controleer jouw bestelling, rond hem af en ontvang deze direct in je mailbox.',

  // Form validators
  'validator.email_invalid': 'Ongeldig e-mail adres',
  'validator.email_mismatch': 'De e-mailadressen komen niet overeen',
  'validator.email_required': 'Het e-mail veld is verplicht',
  'validator.first_name_required': 'Het voornaam veld is verplicht',
  'validator.ideal_issuer_required': 'De iDEAL betaalmethode vereist de selectie van je bank',
  'validator.last_name_required': 'Het achternaam veld is verplicht',
  'validator.payment_method_required': 'Je dient een betaalmethode te selecteren',
  'validator.privacy_required': 'Je dient akkoord te gaan met de privacy policy',
  'validator.products_required': 'Je hebt geen producten geselecteerd',
  'validator.required': 'Dit veld is verplicht',
  'validator.terms_required': 'Je dient akkoord te gaan met de algemene voorwaarden',
  'validator.phone_required': 'Het telefoonnummer veld is verplicht',

  // Warnings
  'warning.max_voucher_products_reached': 'Je kunt met deze kortingscode 1 ticket toevoegen',
  'warning.no_products': 'Geen producten geselecteerd',
  'warning.no_timeframes': 'Helaas is er op deze dag geen beschikbaarheid meer. Kijk snel voor een andere dag.',
  'warning.old_browser':
    'Je maakt gebruik van een <strong>verouderde</strong> browser. <a>Upgrade je browser</a> om het gebruik en de veiligheid te verbeteren.',

  // Alerts
  'alert.closed.winter.season': '<b>LET OP:</b> Klimrijk is deze winter uitsluitend geopend voor de indoor activiteiten. Bekijk <a>hier</a> de mogelijkheden.',

  // Somerdrome
  'tickets.timeframe.tabs_day': 'Dag tickets',
  'tickets.timeframe.tabs_evening': 'Avond tickets',
  'tickets.timeframe_v2.tabs_day': '<strong>Dag entree</strong>',
  'tickets.timeframe_v2.tabs_evening': '<strong>Middag entree</strong>',
  'tickets.timeframe.tabs_day.subtitle': 'Toegang v.a. 10:00 uur',
  'tickets.timeframe.tabs_evening.subtitle': 'Toegang v.a. 15:00 uur',
  'tickets.timeframe.tabs_day.price': '<strong>v.a. € 18,00</strong>',
  'tickets.timeframe.tabs_evening.price': '<strong>€ 17,50</strong>',
  'tickets.timeframe.tabs_evening.price_beeksebergen': '<strong>€ 16,50</strong>',
  'somerdrome.day.timeframes_subtitle':
    'Kies een aankomsttijd tussen <strong>10:00</strong> en <strong>15:00</strong> uur en krijg de mogelijkheid om tot maar liefst <strong>21:00</strong> uur in Safaripark Beekse Bergen te blijven.',
  'somerdrome.evening.timeframes_subtitle':
    'Kies een aankomsttijd tussen <strong>15:00</strong> en <strong>20:00</strong> uur en krijg de mogelijkheid om tot maar liefst <strong>21:00</strong> uur in Safaripark Beekse Bergen te blijven.',

  // Age Consent
  'age_consent.title': 'Ben je 18 jaar of ouder?',
  'age_consent.text': 'Je moet 18 jaar of ouder zijn om dit evenement te bezoeken.',
  'age_consent.button.affirmative': 'Ja, ik ben 18 of ouder',
  'age_consent.button.negative': 'Nee',
  'age_consent.nix18.see_more': 'Lees meer op de website van',
  'age_not_consent.title': 'Nog geen 18 jaar?',
  'age_not_consent.text': 'Dit evenement is helaas niet geschikt voor jouw leeftjid.',
  'age_not_consent.button': 'Terug naar de website',
  'age_not_consent.back': 'Terug',

  // Order Confirmation (LightSafari)
  'order_confirmation.lightsafari.success.banner.title': 'Goed voorbereid op avontuur',
  'order_confirmation.lightsafari.success.banner.subtitle':
    'Download alvast de Beekse Bergen app. Hier vind je straks meer informatie over wat er tijdens de Light Safari allemaal te doen is en welke horecapunten er zijn. Ook heb je zo de plattegrond altijd bij de hand.',
  'order_confirmation.lightsafari.success.banner.button': 'Ontdek de app',
  'order_confirmation.lightsafari.success.banner.button_url': 'https://www.beeksebergen.nl/app',

  // Order Confirmation (BeekseBergen)
  'order_confirmation.success.banner.title': 'Goed voorbereid op avontuur',
  'order_confirmation.success.banner.subtitle':
    'Download alvast de Beekse Bergen app. Hier vind je informatie over alles wat er in Beekse Bergen te beleven is. Ook heb je zo de plattegrond altijd bij de hand.',
  'order_confirmation.success.banner.button': 'Ontdek de app',
  'order_confirmation.success.banner.button_url': 'https://www.beeksebergen.nl/app',

  // Order Confirmation (BrewAtTheZoo)
  'order_confirmation.brewatthezoo.success.title': 'Gelukt! Je bent erbij!',
  'order_confirmation.brewatthezoo.success.subtitle': `<div>
  <p style="margin-bottom: 8px">
    Heb jij ook zo'n zin in Brew@theZoo bij Safaripark Beekse Bergen? Om goed voorbereid op 'biersafari' te gaan, hebben wij nog een paar
    belangrijke punten op een rijtje gezet:
  </p>
  <ol style="list-style:decimal;margin-bottom:8px;padding-left:24px">
    <li>
      Je ontvangt je gekochte Brew@theZoo tickets en reserveringsbevestiging op het opgegeven e-mailadres. Let op: mocht je jouw tickets
      niet direct ontvangen, controleer dan of deze in je spam of ongewenste e-mail terecht zijn gekomen.
    </li>
    <li>Neem je tickets én reserveringsbevestiging mee naar het evenement.</li>
    <li>Heb je tickets met korting gekocht? Zorg dan dat je je abonnement of Attractiepas ook meeneemt.</li>
    <li>Op de hoogte blijven van het laatste nieuws in Beekse Bergen? Schrijf je in voor de <a href="https://www.beeksebergen.nl/nieuwsbrief">nieuwsbrief</a>.</li>
  </ol>
  <p style="margin-bottom: 8px">
    We gaan ons best doen om weer een onvergetelijke editie van Brew@theZoo neer te zetten samen met jou. Tot dan, proost!
  </p>
</div>`,
  'order_confirmation.brewatthezoo.success.banner.title': 'Blijven slapen tijdens Brew@theZoo?',
  'order_confirmation.brewatthezoo.success.banner.subtitle':
    'Dat kan! Overnacht tussen de wilde dieren op het Safari Resort, midden in de natuur op het Lake Resort of met hotelservice in het nieuwe Safari Hotel.',
  'order_confirmation.brewatthezoo.success.banner.button': 'Bekijk alle accommodaties',
  'order_confirmation.brewatthezoo.success.banner.button_url': 'https://www.beeksebergen.nl/overnachten/zoek-en-boek',
}
